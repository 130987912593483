import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router'


import { OhVueIcon, addIcons } from 'oh-vue-icons'
import { BiAlarmFill, HiSolidShoppingCart, BiPlusCircle, BiXCircle } from 'oh-vue-icons/icons'
addIcons(BiAlarmFill, HiSolidShoppingCart, BiPlusCircle, BiXCircle)

import { Vue3Mq } from "vue3-mq";


//createApp(App).use(router).mount('#app')
createApp(App).use(router, Vue3Mq, {
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
      xxl: Infinity
    },
    defaultBreakpoint: "xl"
  }).component('v-icon', OhVueIcon).mount('#app')