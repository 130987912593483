<template >
    <!--<MqResponsive target="lg-xl">
    <CNavbar expand="md" color-scheme="primary" class="bg-primary">
      <CContainer fluid>
        <CNavbarBrand ></CNavbarBrand>
        <CNavbarNav>
          <CDropdown dark variant="nav-item">
            <CDropdownToggle color="primary" :navLink="false" title="Histórico"><span ><v-icon name="bi-alarm-fill"  scale="1.5"/> </span></CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href="#">Action</CDropdownItem>
              <CDropdownItem href="#">Another action</CDropdownItem>
              <CDropdownItem href="#">Something else here</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          <CDropdown dark variant="nav-item">
          <CDropdownToggle color="primary" :navLink="false" title="Carrinho"><span ><v-icon name="hi-solid-shopping-cart"  scale="1.5"/> </span></CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href="#">Action</CDropdownItem>
              <CDropdownItem href="#">Another action</CDropdownItem>
              <CDropdownItem href="#">Something else here</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CNavbarNav>
      </CContainer>
    </CNavbar>
  </MqResponsive>-->
  <div>
    
    
    </div>
    <!--<router-view></router-view>-->
    <RouterView></RouterView>
  
</template>

<script>


//import Pedido from './components/Pedido.vue';
//import Teste from './components/Teste.vue';
//import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CNavbar, CContainer, CNavbarBrand,CNavbarNav } from '@coreui/vue'
//import { MqResponsive } from "vue3-mq";


export default {
  name: 'App',
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  font-size: small;
  
}
</style>
