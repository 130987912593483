import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/",
    name: "hello",
    component: () => import("./components/HelloWorld")
  },
  {
    path: "/pedido/:id",
    name: "pedido",
    component: () => import("./components/Pedido")
  },
  {
    path: "/:id",
    name: "pedido1",
    component: () => import("./components/Pedido")
  },
  {
    path: "/teste/:id",
    name: "pedido2",
    component: () => import("./components/Pedido")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;